<template>
  <div>
    <b-button size="sm" variant="success" v-b-modal.modal-1
      >New Project</b-button
    >
    <b-modal id="modal-1" title="Register New Project" size="xl">
      <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
      <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
        <div class="row">
          <div class="col-md-6">
            <b-form-group id="input-group-1" label="Name:" label-for="input-1">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Enter Project Name"
                  required
                ></b-form-input>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group id="input-group-2" label="Code:" label-for="input-2">
              <ValidationProvider rules="alpha" v-slot="{ errors }">
                <b-form-input
                  id="input-2"
                  v-model="form.code"
                  placeholder="Enter Project Code"
                  required
                ></b-form-input>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Description:"
              label-for="input-2"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-textarea
                  id="input-2"
                  v-model="form.description"
                  placeholder="Enter description..."
                  rows="5"
                  max-rows="8"
                ></b-form-textarea>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              id="input-group-2"
              label="Total area:"
              label-for="input-2"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-input
                  id="input-2"
                  type="number"
                  v-model="form.area"
                  placeholder="Enter Total area (In Acres)"
                  required
                ></b-form-input>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Select Organization :"
              label-for="input-2"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-select
                  v-model="form._organizationID"
                  value-field="_id"
                  text-field="name"
                >
                  <option value="">Select Organization</option>
                  <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="option._id"
                  >
                    {{ option.name }}
                  </option>
                </b-form-select>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group id="input-group-2" label="Logo:" label-for="input-2">
              <b-form-file
                id="file"
                ref="file"
                v-model="form.image"
                :state="Boolean(form.logo)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="uploadImage"
              ></b-form-file>
            </b-form-group>
            <div class="row">
              <div class="col-md-6">
                <h5>Boundaries</h5>
              </div>
              <div class="col-md-6 mb-2 text-right">
                <button
                  class="btn btn-sm btn-primary"
                  type="button"
                  @click="addBoundary"
                >
                  New Boundary
                </button>
              </div>
              <div class="col-md-12">
                <table class="table table-sm">
                  <thead class="small thead-light">
                    <tr>
                      <th>#</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(boundary, index) in form.boundaries"
                      :key="index"
                    >
                      <td class="small align-middle">{{ index + 1 }}</td>
                      <td>
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            id="input-2"
                            v-model="boundary.latitude"
                            size="sm"
                            placeholder="Enter Latitude"
                            required
                          ></b-form-input>
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                      <td>
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            id="input-2"
                            v-model="boundary.longitude"
                            size="sm"
                            placeholder="Enter Longitude"
                            required
                          ></b-form-input>
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                      <td>
                        <button
                          class="btn btn-sm btn-danger"
                          type="button"
                          @click="removeBoundary(index)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-form>
      <template #modal-footer="{ ok, close }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="submitForm()">
          Register
        </b-button>
        <b-button size="sm" variant="danger" @click="close()"> Close </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        code: "",
        description: "",
        area: "",
        _organizationID: "",
        image: "",
        boundaries: [],
      },
      options: [],
      success: false,
    };
  },
  mounted() {
    this.getOrganization();
  },
  methods: {
    getOrganization() {
      window.axios.get("organization").then((resp) => {
        if (resp.data.success) {
          console.log(resp.data.data);
          this.options.push(resp.data.data);
        } else {
          alert(resp.data.message);
        }
      });
    },
    submitForm() {
      let formData = new FormData();
      formData.append("image", this.form.image);
      formData.append("code", this.form.code);
      formData.append("name", this.form.name);
      formData.append("description", this.form.description);
      formData.append("area", this.form.area);
      formData.append("_organizationID", this.form._organizationID);
      formData.append("boundaries", JSON.stringify(this.form.boundaries));
      window
        .axios({
          method: "post",
          url: "project",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.success) {
            this.success = resp.data.message;
            this.$emit("project-create", resp.data);
          }
          setTimeout(() => (this.success = false), 3000);
        });
    },
    addBoundary() {
      this.form.boundaries.push({
        latitude: "",
        longitude: "",
      });
    },
    removeBoundary(index) {
      this.form.boundaries.splice(index, 1);
    },
    uploadImage() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>